/* Make it a marquee */
.marquee {
  /* margin: 0 auto; */
  white-space: nowrap;
  overflow: hidden;
  font-family: "Open Sans", sans-serif;
  padding-top: 7px;
  padding-bottom: 11px;
}

.marquee-bg {
  background-image: linear-gradient(
    to bottom,
    #dfeff4,
    #f63800,
    #f63800,
    #f63800,
    #f63800,
    #f63800,
    #561600
  );
  border-radius: 7px;
}

.marquee:hover span {
  -webkit-animation-play-state: paused;
  -moz-animation-play-state: paused;
  -o-animation-play-state: paused;
  animation-play-state: paused;
}

.marquee span:hover {
  color: #ffc107;
  border: 1px dotted #ffc107;
}

.marquee span {
  display: inline-block;
  /* padding-left: 20%; */
  margin-left: 20px;
  margin-top: 8px;
  margin-right: 20px;
  animation: marquee 50s linear infinite;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  color: white;
  text-decoration: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  border: 1px dotted white;
  border-radius: 7px;
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}

.marquee-button-bg {
  margin-top: 8px;
  /* display: flex;
  align-items: center;
  justify-content: flex-start; */
}

/* Make it move */
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-750%, 0);
  }
}
