.App-header {
  background-color: #dfeff4;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  color: #d8d8d8;
  text-align: center;
}

.link-holder {
  height: auto;
  width: 1200px;
  border-radius: 7px;
  text-align: center;
  align-items: center;
  color: #fff;
  margin: 1rem;
}

.logo-holder p {
  margin: 10px;
  padding: 10px;
}

.logo-placeholder {
  height: auto;
  width: 150px;
}

.Title {
  padding: 2.5rem;
  padding-top: 4.3rem;
  color: grey;
}

.col-4 {
  align-self: flex-end;
}

.compliant-img {
  width: auto;
  height: 100px;
}

a img:hover {
  opacity: 0.5;
}
