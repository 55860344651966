/**
 * Styles for the sidebarHeaderNav.js component
 */
.sidebarHeaderNav {
  position: sticky;
  margin-top: 7px;
  bottom: 0;
  left: 0;
  z-index: 1;
  padding: 0;
  overflow-x: hidden;
  overflow-y: hidden;

  background-image: linear-gradient(to right, #404040, #22262e);
  border-right: 1px solid #252931;
  min-height: 100vh;
}
.sidebarHeaderNav .nav {
  margin-bottom: 20px;
}
.sidebarHeaderNav .nav-item + .nav-item {
  margin-left: 0;
}
.sidebarHeaderNav .nav-link {
  color: #6a6e74;
  font-size: 0.95rem;
  padding: 0.75rem 2rem;
}
.sidebarHeaderNav .nav-link i {
  font-size: 1.2rem;
}
.sidebarHeaderNav .nav-link span {
  position: absolute;
}
.sidebarHeaderNav .nav-link.active,
.sidebarHeaderNav .nav-link:hover {
  color: white;
}
.sidebarHeaderNav .nav-link.active {
  background: #141519;
}

.niflag {
  background: url("../img/newniflag.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
